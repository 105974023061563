import { template as template_3e33691e377647ae81e9c4655de9f694 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_3e33691e377647ae81e9c4655de9f694(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;

import { template as template_6da776939c624c0d9a2ddb2f3e70ab55 } from "@ember/template-compiler";
const WelcomeHeader = template_6da776939c624c0d9a2ddb2f3e70ab55(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

import { template as template_8c85a1e330c54e7ebe3d314f585f2f12 } from "@ember/template-compiler";
const FKLabel = template_8c85a1e330c54e7ebe3d314f585f2f12(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
